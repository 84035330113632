<template>
	<section class="content">
		<div class="modal show" tabindex="-1" role="dialog" ref="formClosingClaim" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<div class="modal-content" v-if="bandingClaim == 0">
					<form role="form" @submit.prevent="submitForm">
						<div class="modal-header">
							<h5 class="modal-title">Selesaikan Klaim {{ special ? 'Khusus' : '' }}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<div class="row">
                                <span class="callout callout-warning">Form ini menandai berakhirnya proses claim atas retur pesanan.</span>
 
								<div class="alert alert-danger" v-if="message.length">{{message}}</div>
								
								<div class="col-md-12 form-group">
									<label class="control-label">Catatan</label>
									<input type="text" maxlength="300" class="form-control" v-model="form.note"
										/>
                                        <small>Masukkan catatan jika ada</small>
								</div>
							</div>
						</div>
                        <div class="modal-footer">
                            <button type="submit" @click="submitType = 'approve'" :disabled="processing" class="btn btn-success">Setujui Claim</button>
                            <button type="submit" @click="submitType = 'reject'" :disabled="processing" class="btn btn-danger">Tolak Claim</button>
                            <button type="button" @click="bandingClaim = '1'" :disabled="processing" class="btn btn-warning text-light" v-if="courier">Banding Claim</button>
                            <button type="submit" @click="submitType = 'void'" :disabled="processing" class="btn bg-indigo text-light" v-if="voidRole && !special">Void</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
					</form>
				</div>
				<div class="modal-content" v-else>
					<div class="modal-body m-3" v-if="bandingClaim == 1">
						<h4 class="text-center font-weight-bold">Alasan Banding</h4>
						<form role="form" @submit.prevent="submitForm">
							<div class="form-group h6">
								<label v-for="(template, index) in templateBanding" class="d-flex align-items-center font-weight-normal" style="gap: 5px">
									<input type="radio" name="delete_reason" :value="template" @change="textBandingChange(index)" v-model="textBanding" /> {{template}}
								</label>
							</div>
							<div class="form-group" v-if="customBanding">
								<label>Ceritakan Alasanmu</label>
								<textarea class="form-control" placeholder="Tuliskan alasan pembatalan" v-model="textBanding"></textarea>
								<small class="d-block text-right mt-1" :class="'text' + colorBanding">Minimal 25 karakter</small>
							</div>
							<div class="form-group">
								<input type="file" accept="image/*" name="userfile[]" id="userfile" ref="userfile" multiple
									style="display: none" @change="uploadFiles" />
								<button type="button" @click="showUpload" class="btn-xs btn btn-secondary">
									<i class="fa fa-paperclip"></i> Lampiran
								</button>
								<ul class="pt-2">
									<li v-for="file in files">
									{{ file.name }}
									<span>
										<div class="spinner-border spinner-border-sm" role="status" v-if="!file.status">
										<span class="sr-only">Loading...</span>
										</div>
										<button v-if="file.status" :data-id="file.code" @click="removeFile(file.code)" type="button"
										class="btn btn-xs">
										<i class="fa fa-times"></i>
										</button>
									</span>
									</li>
								</ul>
							</div>
							<div class="d-flex justify-content-between">
								<button type="reset" @click="resetBanding" class="btn btn-lg btn-outline-primary w-49">Batal</button>
								<button type="submit" @click="submitType = 'banding'" class="btn btn-lg btn-primary w-49" :disabled="deleting">Lanjutkan</button>
							</div>
						</form>
					</div>
					<div class="modal-body m-3" v-if="bandingClaim == 2">
						<img src="../assets/img/cancel_order.svg" class="d-block m-auto" />
						<h5 class="text-center font-weight-bold">{{submitType}} berhasil</h5>
						<div class="text-center">Terimakasih!</div>
						<div>&nbsp;</div>
						<button class="btn btn-block btn-lg btn-primary" data-dismiss="modal">Kembali ke riwayat kiriman</button>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import { authFetch, auth } from "@/libs/hxcore";
import $ from "jquery";
import Swal from "sweetalert2";

export default {
	name: "FormClosingClaim",
	props: {
		show: Boolean,
		value: Array,
		orderId: String,
		courier: Boolean,
		special: Boolean
	},
	data() {
		return {
			orderOptions: [],
			errors: [],
			categories: [],
			priorityOpt: [],
			method: "POST",
			selectedId: 0,
			defaultId: 1,
			addrOptions: [],
			addrJne: [],
			courierBranchOption: [],
			formTitle: "Buat Tiket",
			files: [],
			loaded: false,
            message: "",
            submitType: '',
			form: {
				//to create pickup address
				id: "",
				items: [],
				category: "",
				attachments: ""
			},
            processing: false,
			bandingClaim: 0,
			textBanding: '',
			templateBanding: [
				'Tidak ada konfirmasi retur',
				'Seller belum menyetujui retur',
				'Bukti dari kurir tidak jelas',
				'Lainnya',
			],
			customBanding: false,
			colorBanding: 'dark',
		};
	},
	components: {
	},
	computed: {
		voidRole() {
			const allowRoles = ['1', '2', '4', '10'];
			return allowRoles.includes(this.roleid);
		},
	},
	created() {
		this.userInfo = auth.user();
		if (this.userInfo) {
			this.roleid = this.userInfo.role_id;
		}
	},
	methods: {
		showUpload: function (ev) {
			//$(this.$refs.formUpload).modal("show");
			$(this.$refs.userfile).click();
		},
		uploadFiles: function (event) {
			const e = this.$refs;
			var input = event.target;
			this.files = input.files;
			var formData = new FormData();
			for (var i in this.files) {
				formData.append("file[]", input.files[i]);
			}
			formData.append("refcode", this.orderId);
			//formData.append("subdir", "/ticket/" + new Date().getTime() / 1000);
			authFetch("/ticket/upload", {
				method: "POST",
				body: formData,
				headers: {
					"Content-Type": null,
				},
			})
			.then((res) => res.json())
			.then((json) => {
				console.log(json);
				this.files = json;
			});
		},
		removeFile: function (code) {
			let newFiles = this.files.filter(function (arr, i) {
			return arr.code != code;
			});
			this.files = newFiles;
		},
		textBandingChange: function (index) {
			if (index == 3) {
				this.textBanding = '';
				this.customBanding = true;
			} else {
				this.textBanding = this.templateBanding[index];
				this.customBanding = false;
				this.colorBanding = 'dark';
			}
		},
		resetBanding: function () {
			this.bandingClaim = 0;
			this.textBanding = '';
			this.customBanding = false;
			this.colorBanding = 'dark';
		},
		submitForm: function (ev) {
			if (this.submitType != 'banding') {
			} else if (this.customBanding && this.textBanding.length < 25) {
				Swal.fire('Minimal 25 karakter');
	            this.colorBatal = 'danger';
	            return;
	        } else if (this.textBanding == '') {
				Swal.fire('Pilih alasan pembatalan');
	            return;
			}

			this.processing = true;
			var items = [];
            let submitType = this.submitType;
			/*if(this.files.length == 0)
			{
				Swal.fire("Pilih lampiran", "Lampiran tidak boleh kosong", "error");
				return false;
			}*/
			for (var i in this.files) {
				items.push(this.files[i].code);
			}
			if (!this.value.length) return;
			var data = {
                orders: this.value,
                type: submitType,
				note: this.form.note,
				reason: this.textBanding,
				attachments: items.join(","),
			};
            
            /* Confirmation Claim */
			const swalConfirmButtons = Swal.mixin({
				customClass: {
				title: 'h4',
				actions: 'd-flex justify-content-between flex-row-reverse',
				cancelButton: 'btn btn-lg btn-outline-primary w-49 font-weight-bold',
				confirmButton: 'btn btn-lg btn-primary w-49 font-weight-bold',
				},
				buttonsStyling: false
			});
            swalConfirmButtons.fire({
                title: `Apakah anda yakin ${submitType} ${this.special ? "special" : ""} claim?`,
                showCancelButton: true,
                cancelButtonText: "Tidak",
                confirmButtonText: `Ya, ${submitType}`,
            }).then((result) => {
                if (result.isConfirmed) {
                    authFetch("/ticket/claim/" + (this.special ? (submitType == 'banding' ? "banding_special" : "close_special") : submitType == 'banding' ? "banding_claim" : "close"), {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(data)
                    })
                    .then(res => {
                        if (res.status === 201) {} else if (res.status === 400) {}
                        return res.json();
                    })
                    .then(js => {
                        this.processing = false;
                        if (js.success) {
                            this.form = {};
                            this.files = [];
                            this.$emit("change", true);
                            $(this.$refs.formClosingClaim).modal("hide");
                            Swal.fire("Proses Berhasil", ``, "success");
                        } else {
                            this.message = js.desc;
                            Swal.fire("Proses gagal", `${js.desc}`, "error");
                        }
                    });
					this.resetBanding();
                } else {
                    this.processing = false;
                }
            });
		},
	},
	mounted() {
		// console.log("warehouse1", this.value);
		const e = this.$refs;
		// if (this.show === true) $(e.formSelectPickup).modal("show");
		// console.log(this.show);
		$(e.formClosingClaim).on("show.bs.modal", e => {
			if (!this.loaded) {
				this.loaded = true;
			}
		});

		$(e.formClosingClaim).on("hide.bs.modal", e => {
			this.message = "";
		});
	},
	watch: {
		show: function (old, val) {
			$(this.$refs.formClosingClaim).modal("show");
		},
		orderId: function (old, val) {
			//this.form.refcode = this.orderId;
		},
		value: function (old, val) {},
		courier: function (old, val) {},
	}
};
</script>
<style scoped>
</style>